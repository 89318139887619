<template>
    <div>
        <v-progress-linear indeterminate v-if="loading" color="q_leads_4"/>
        <q-leaderboard-data-table :show-all="showAll" :title="the_title" :subtitle="subtitle" :rows="rows" :leaderboard-data="leaderboard_data.data" :agentPosition="agentPosition.data" :start-date="displayStartDate" :end-date="displayEndDate"/>
    </div>
</template>
<script>
import QLeaderboardDataTable from './QLeaderboardDataTable.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QLeaderboardDataTableWrapper",
    props: {
        'agentCode': {
            type: String,
            default: null
        },
        'type': {
            type: String,
            default: null
        },
        'timeFrame': {
            type: String,
            default: null
        },
        'title': {
            type: String,
            default: null
        },
        'subtitle': {
            type: String,
            default: null
        },
        'showAll': {
            type: Boolean,
            default: false
        },
        'rows': {
            type: Number,
            default: 5
        },
        'startDate': {
            type: String,
            default: null
        },
        'endDate': {
            type: String,
            default: null
        }
    },
    data () { // data internal to component (not available outside)
        return {
            leaderboard_data: {
                data: [],
            },
            agentPosition: {
                data: {},
            },
            loading: false,
            displayStartDate: this.startDate,
            displayEndDate: this.endDate
        }
    },
    mounted () {
        this.loadData()
    },
    computed: {
        the_title () {
		  return this.title + ' ' + this.titleCase(this.timeFrame) + ' Leaderboard'
        }
    },
    methods: {
        loadData () {
            this.loading = true;
            const filters = {
                startDate: this.startDate,
                endDate: this.endDate
            }

		  QuilityAPI.loadLeaderboard(this.timeFrame, this.type, this.agentCode, this.rows, this.showAll, filters).then((json) => {
                this.$set(this.leaderboard_data, 'data', json.data)
                this.$set(this.agentPosition, 'data', json.meta.agent_position)
				this.$set(this, 'displayStartDate', json.meta.start_date)
				this.$set(this, 'displayEndDate', json.meta.end_date)
                this.loading = false
                this.$emit('loaded');
            }).catch(() => {
			   this.loading = false
            })
        }
    },
    watch: {
		timeFrame () {
		  this.$nextTick(this.loadData)
		},
        startDate (newV) {
            this.displayStartDate = newV;
            this.$nextTick(this.loadData)
        },
    },
    components: {
        QLeaderboardDataTable
    }
}

</script>
<style>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}

</style>
