<template>
    <div class="q-user-menu" :class="isMobile && 'q-user-menu--mobile'">
        <v-menu v-model="showUserMenu" offset-y :nudge-top="-10" transition="slide-y-transition" content-class="q-user-menu__content">
            <template v-slot:activator="{ on, attrs }">
				<v-btn
					depressed
					:ripple="false"
					:color="btnColor"
					class="q-app-bar-btn__user-menu"
					v-bind="attrs"
					v-on="on"
				>
                    <v-avatar size="52" v-if="doShowAvatar">
                        <v-img :src="user.avatar" :alt="user.name" />
                    </v-avatar>
                    <v-icon v-else>icon-user-q</v-icon>
                </v-btn>
            </template>

            <v-list class="q-user-menu__list">
                <div class="list-item__welcome">
                    <v-list-item-title class="list-item__welcome-content">
                        Welcome<br />
						<span><strong>{{ $auth.auth_user.name}}</strong>!</span>
                        <span v-if="isImpersonating" class="pt-2"><em>Impersonating</em></span>
                    </v-list-item-title>
                </div>

                <v-list-item v-if="isImpersonating" @click="revertImpersonation">
                    <v-list-item-avatar>
                        <v-icon small>fas fa-undo-alt</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Switch Back</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name:'User Profile'}">
                    <v-list-item-avatar>
                        <v-icon small>icon-user-q</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>My Profile</v-list-item-title>
                </v-list-item>
                <v-list-item :to="'/user/account_balance'" v-if="user.Agent.AgentAccount">
                    <v-list-item-avatar>
                        <v-icon small>fas fa-dollar-sign</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Account Balance: {{ formatCurrency(user.Agent.AgentAccount.Balance) }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="'/user/account_balance'" v-else>
                    <v-list-item-avatar>
                        <v-icon small>icon-wallet-q</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Add Credit Card</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name:'My Exports'}">
                    <v-list-item-avatar>
                        <v-icon small>icon-file-chart-q</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>My Exports</v-list-item-title>
                </v-list-item>
                <v-menu v-if="hasDemoPermission" open-on-hover left offset-x transition="slide-x-transition">
                    <template v-slot:activator="{ attrs, on }">
                        <v-list-item v-on="on">
                            <v-list-item-avatar>
                                <v-icon small>fas fa-user</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>Demo Mode</v-list-item-title>
                            <v-list-item-icon style="align-self: center;">
                                <v-icon v-if="attrs['aria-expanded'] === 'true'" small>fas fa-chevron-down</v-icon>
                                <v-icon v-else small>fas fa-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </template>
                    <v-list style="min-width:250px;">
                        <v-list-item @click="demoUnlicensedAgentMode()">
                            <v-list-item-avatar>
                                <v-icon small>fas fa-user</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>Unlicensed Agent</v-list-item-title>
                            <v-switch v-model="unlicensedAgentMode" disabled class="ml-3" />
                        </v-list-item>
                        <v-list-item @click="demoTeamLeaderMode()">
                            <v-list-item-avatar>
                                <v-icon small>fas fa-user</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>Team Leader</v-list-item-title>
                            <v-switch v-model="teamLeaderAgentMode" disabled class="ml-3" />
                        </v-list-item>
                        <v-list-item @click="demoSFGDirectMode()">
                            <v-list-item-avatar>
                                <v-icon small>fas fa-user</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>SFG Direct</v-list-item-title>
                            <v-switch v-model="sfgDirectAgentMode" disabled class="ml-3" />
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-divider v-if="raw_divisions.length > 1" class="q-user-menu__list-divider" />

                <v-item-group v-if="raw_divisions.length > 1 ">
                    <v-subheader>Divisions</v-subheader>
                    <v-list-item v-for="(d, key) in raw_divisions" @click="changeDivision(d)" :key="key">
                        <v-list-item-avatar>
                            <v-icon small>icon-q-right-arrow</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title>{{ d }}</v-list-item-title>
                    </v-list-item>
                </v-item-group>
                <v-divider class="q-user-menu__list-divider" />
                <v-list-item @click="logout">
                    <v-list-item-avatar>
                        <v-icon small>icon-q-logout</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { EventBus } from '@/event-bus';
import { UNLICENSED_AGENT_CODE, TEAM_LEADER_AGENT_CODE, SFG_DIRECT_AGENT_CODE } from '@/constants';

export default {
    name: "QUserMenu",

    props: {
        showAvatar: { type: Boolean, default: true },
		color: { type: String, default: '#46C3B2' },
    },

    data() {
        return {
            showUserMenu: false,
        };
    },

	computed: {
        hasDemoPermission() {
            return this.hasRole(["Staff", "SuperAdmin"]) && this.hasPermission('manage:Demo')
                || this.isDemoMode
        },
		isMobile () {
			return this.$vuetify.breakpoint.xs
		},
		doShowAvatar () {
			return this.showAvatar && this.user.avatar
		},
		btnColor () {
			return this.doShowAvatar ? 'transparent' : this.color
		},
	},

    created() {
        const agentCode = localStorage.getItem('demo_mode')
        if (agentCode) {
            this.demoModeAgent(agentCode)
            localStorage.removeItem('demo_mode')
        }
    },

    methods: {
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        },
        demoModeAgent(agentCode) {
            return QuilityAPI.demoModeAgent(agentCode).then((json) => {
                if (json.success === true) {
                    document.location.href = process.env.VUE_APP_BASE_URL
                }
            })
        },
        revertDemoMode(redirectToHome = false) {
            return QuilityAPI.revertDemoMode().then(function(json) {
                if (json.success == true) {
                    //redirect the admin back to the detail page for that agent. 
                    document.location.href = process.env.VUE_APP_BASE_URL + "/agents/" + json.AgentCode;
                    document.location.href = redirectToHome
                        ? process.env.VUE_APP_BASE_URL
                        : process.env.VUE_APP_BASE_URL + "/agents/" + json.AgentCode;
                }
            })
        },
        revertImpersonation(redirectToHome = false) {
            return QuilityAPI.revertImpersonation().then(function(json) {
                if (json.success == true) {
                    //redirect the admin back to the detail page for that agent. 
                    document.location.href = process.env.VUE_APP_BASE_URL + "/agents/" + json.AgentCode;
                    document.location.href = redirectToHome
                        ? process.env.VUE_APP_BASE_URL
                        : process.env.VUE_APP_BASE_URL + "/agents/" + json.AgentCode;
                }
            })
        },
        changeDivision(d) {
            EventBus.$emit('changeDivision', d)
        },
        async demoUnlicensedAgentMode() {
            this.showUserMenu = false

            if (this.isImpersonating) {
                if (!this.unlicensedAgentMode) {
                    localStorage.setItem('demo_mode', UNLICENSED_AGENT_CODE)
                }
                this.revertDemoMode(true)
                return
            }
            this.demoModeAgent(UNLICENSED_AGENT_CODE)
        },
        async demoTeamLeaderMode() {
            this.showUserMenu = false

            if (this.isImpersonating) {
                if (!this.teamLeaderAgentMode) {
                    localStorage.setItem('demo_mode', TEAM_LEADER_AGENT_CODE)
                }
                this.revertDemoMode(true)
                return
            }
            this.demoModeAgent(TEAM_LEADER_AGENT_CODE)
        },
        async demoSFGDirectMode() {
            this.showUserMenu = false

            if (this.isImpersonating) {
                if (!this.sfgDirectAgentMode) {
                    localStorage.setItem('demo_mode', SFG_DIRECT_AGENT_CODE)
                }
                this.revertDemoMode(true)
                return
            }
            this.demoModeAgent(SFG_DIRECT_AGENT_CODE)
        },
    },
}
</script>

<style lang="scss">
.q-user-menu__content.v-menu__content {
	border-radius: 0 0 6px 6px;
}
</style>


<style lang="scss" scoped>
.q-user-menu {
	--margin: 1.5rem;
	--size: 52px;
	--icon-size: 24px;

	&--mobile {
		--margin: 0.5rem;
		--size: 36px;
		--icon-size: 20px;
	}

	margin-left: var(--margin);
	display: flex;
	justify-content: center;
	align-items: center;

	.q-app-bar-btn__user-menu {
		height: var(--size) !important;
		width: var(--size) !important;
		min-width: var(--size) !important;
		padding: 0 !important;
		border-radius: 50%;
		transition: opacity 0.2s;

		&::before {
			opacity: 1 !important;
			background-color: transparent;
		}

		&:hover {
			opacity: 0.7;
		}

		.v-icon.v-icon {
			font-size: var(--icon-size) !important;
		}
	}

	&__list {
		min-width: 250px;
		padding: 0;

		.list-item__welcome {
			padding: 0.75rem 1rem 1rem;
		}

		.list-item__welcome-content {
			display: flex;
			flex-direction: column;
			row-gap: 0.25rem;
			line-height: 1;
		}

		.q-user-menu__list-divider {
			margin: 0.5rem 1rem;
		}

		.v-list-item {
			color: rgba(0, 0, 0, 0.87) !important;
            font-weight: 500;

			.v-icon {
				color: rgba(0, 0, 0, 0.4);
			}
		}

		.v-list-item__avatar:first-child {
			margin-right: 4px;
		}

		.v-list-item.list-item__active-alert:not(.v-list-item--active) {
			color: #0083ea !important;
            font-weight: 600;

			.v-icon {
				color: inherit;
			}
		}

        .v-list-item--active {			
			background-color: #8D8D8D !important;

            &:before {
                background-color: transparent !important;
            }

			&:not(:hover) {
				color: white !important;
			}

			.v-icon {
				color: inherit;
			}
        }

        .v-list-item:hover {
            background-color: #E4E4E4 !important;
            text-decoration: none !important;

			&:before {
				opacity: 0;
			}

			.v-list-item__title {
				text-decoration: underline;
			}

			.v-icon {
				color: inherit;
			}
        }
    }
}
</style>
